import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import ReactPlayer from "react-player/youtube";
import getVideoId from "get-video-id";
import gsap from "gsap";

import { media } from "utils/Media";

import Layout from "../../components/Layout/LayoutNewYork";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

const ContentHubStyles = styled.section`
  width: 100%;
  padding: 3rem 0;
  background: #000;
  color: #fff;
  .hidden-tablet {
    @media ${media.sm} {
      display: none;
    }
  }
  .anchor {
    position: absolute;
    top: -6rem;
  }
  .mt-5 {
    margin-top: 3rem;
  }
  .mb-3 {
    padding-bottom: 2rem;
  }
  .mb-6 {
    padding-bottom: 4rem;
  }
  .font-light {
    font-weight: 200!important;
  }
  h1 {
    text-align: center;
    position: relative;
    color: #fff;
    display: block;
    .title {
      position: relative;
      font-size: 30px;
      z-index: 5;
      @media ${media.md} {
        font-size: 32px;
      }
      @media ${media.xl} {
        font-size: 60px;
      }
    }
    .lines {
      width: 90%;
      z-index: 0;
      margin: 10px auto 0 auto;
      text-align: center;
      left: 5%;
      bottom: 8.5px;
      height: 2px;
      position: absolute;
      display: block;
      background: #ff0000;
      &:after {
        width: 95%;
        height: 2px;
        left: 2.5%;
        position: absolute;
        bottom: -5px;
        background-color: #ff6a07;
        content: " ";
        display: block;
      }
      &:before {
        width: 90%;
        height: 2px;
        left: 5%;
        position: absolute;
        bottom: -10px;
        background-color: #ffcc00;
        content: " ";
        display: block;
      }
      @media ${media.xl} {
        bottom: 15px;
      }
    }
  }
  h3 {
    color: #fff;
    text-align: center;
    font-size: 26px;
    width: 280px;
    margin: 0 auto;
    @media ${media.sm} {
      width: 100%;
    }
    @media ${media.xl} {
      font-size: 40px;
    }
  }
  .nav {
    text-align: center;
    margin: 0 auto;
    ul {
      list-style: none;
      text-align: center;
      margin: 2rem auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 0;
      @media ${media.sm} {
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2rem;
      }
      @media ${media.md} {
        width: 70%;
      }

      li a {
        text-decoration: none;
        color: #fff;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 20px;
        line-height: auto;
        &:hover {
          background: linear-gradient(233deg, #f00 14.99%, #fc0 81.81%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .sideBySide {
    max-width: 1640px;
    margin: 0 auto;
    @media ${media.md} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }
  }
  .full-video {
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    aspect-ratio: 16 / 9;
    background: linear-gradient(232.66deg, #ff0000 14.99%, #ffcc00 81.81%);
    padding: 3px;

    div {
      height: 180px;
      background: #343434;
      position: relative;
      max-width: 750px;
      @media ${media.sm} {
        height: 432px;
      }
      @media ${media.xl} {
        height: 750px;
      }
      span {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  h2 {
    text-align: center;
    color: #fff;
    font-size: 30px;
    margin-top: 3rem;
    @media ${media.sm} {
      font-size: 32px;
    }
    @media ${media.xl} {
      font-size: 60px;
      margin-bottom: 5rem;
    }
  }
  .cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 3rem 0;
  }
  .press {
    margin: 2rem 0;
    position: relative;
    .slider {
      width: 300px;
      margin: 2rem auto;
      max-width: 1460px;
      /* max-width: 1360px; */
      .slick-slide {
        padding: 0 10px;
        margin: 0 auto;
        text-align: center;
      }
      .button {
        display: flex;
        margin-top: 1rem;
        justify-content: space-between;

        div {
          cursor: pointer;
          &:nth-child(1) {
            transform: rotate(180deg);
          }
        }
        @media ${media.md} {
          width: 90%;
          margin: 2rem auto 0 auto;
        }
        @media ${media.xl} {
          width: 95%;
        }
      }
      @media ${media.sm} {
        width: 90%;
      }
      @media ${media.md} {
        width: 100%;
      }
      @media ${media.lg} {
        width: 91%;
      }
    }
  }
`;

const CardSectionStyles = styled.div`
  width: 100%;
  position: relative;
  .wrapper {
    width: 90%;
    margin: 2rem auto;
    max-width: 1390px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media ${media.md} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    .card {
      cursor: pointer;
      @media ${media.md} {
        flex-basis: 47.5%;
      }
      @media ${media.lg} {
        flex-basis: 48%;
      }
      .image {
        background: linear-gradient(232.66deg, #ff0000 14.99%, #ffcc00 81.81%);
        padding: 3px;
        position: relative;
        width: 100%;
        .gatsby-image-wrapper {
          width: 100%;
        }
        span {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      p {
        text-align: center;
        margin-top: 0.5rem;
        font-weight: 600;
        margin-bottom: 0;
        font-size: 18px;
      }
      .audio {
        margin: 0 auto;
        text-align: center;
        a {
          font-weight: 700;
          position: relative;
          display: inline-block;
          cursor: pointer;
          text-decoration: none;
          color: #fff;
          &:hover {
            background: linear-gradient(233deg, #f00 14.99%, #fc0 81.81%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &::after {
              background: linear-gradient(233deg, #f00 14.99%, #fc0 81.81%);
            }
          }
          &::after {
            width: 100%;
            height: 2px;
            background: #fff;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            /* display: inline-block; */
          }
        }
      }
    }
  }
`;

const StyledModal = styled.aside`
  width: 100%;
  position: fixed;
  inset: 0;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  .inner-modal {
    opacity: 0;
    transform: scale(0.85);
    position: relative;
    width: 80%;
    height: auto;
    max-width: 1200px;
    aspect-ratio: 16 / 9;
    span {
      position: absolute;
      right: -25px;
      z-index: 99;
      top: -25px;
      svg {
        width: 50px;
        height: 50px;
      }
    }
  }
`;

const ContentHub = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allContentHubJson {
        nodes {
          cards {
            name
            cards {
              video
              audio
              defaultPlayer
              external
              image {
                childImageSharp {
                  gatsbyImageData(width: 670)
                }
              }
              text
              videoLink
            }
          }
          consideration {
            name
            role
          }
          articles {
            link
            text
            img {
              childImageSharp {
                gatsbyImageData(width: 420)
              }
            }
            logo {
              childImageSharp {
                gatsbyImageData(width: 116)
              }
            }
          }
        }
      }
    }
  `);
  const { cards, articles, consideration } = data.nodes[0];
  const videoRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState("");
  const openModalandSetVideo = (link, video, defaultPlayer) => {
    console.log(defaultPlayer)
    if (video) {
      if (defaultPlayer) {
        setVideo(defaultPlayer);
        console.log(defaultPlayer)
      }else {
        setVideo(link);

      }
      // togglePlayPause()
      setOpen(!open);
    }
  };

  //   const togglePlayPause = () => {
  //     const player = videoRef.current;
  //     if (!player) return;
  //     if (!player.getCurrentTime() || player.getCurrentTime() > 0 && !player.getInternalPlayer().paused) {
  //         player.pause(); // pause the video
  //     } else {
  //         player.play(); // play the video
  //     }
  // };

  const target = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .to(target.current, {
          opacity: 1,
          visibility: "visible",
        })
        .to(
          ".inner-modal",
          {
            opacity: 1,
            visibility: "visible",
            scale: 1,
          },
          "<",
        );
    }, target);
  }, []);
  useEffect(() => {
    if (!open) {
      tl.current.reverse();
    } else {
      tl.current.play();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  });

  const fullWidthVideo = getVideoId("https://youtu.be/lvrF1MeWzPY");
  const anotherVideo = getVideoId(
    "https://www.youtube.com/watch?v=5ck27i9TfdE",
  );
  const secondFullWidthVideo = getVideoId(
    "https://www.youtube.com/watch?v=rUp7caDMBro&feature=youtu.be",
  );
  return (
    <Layout
      title="Content Hub | New York | Back to the Future the Musical"
      description="Need this "
      booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
    >
      <ContentHubStyles>
        <h1>
          <span className="title">
            For your <br className="hidden-tablet" /> consideration
          </span>
          <span className="lines"></span>
        </h1>
        <div className="nav">
          <ul>
            {cards.map((link, i) => {
              return (
                <li key={i}>
                  <a href={`#${slugify(link.name)}`}>{link.name}</a>
                </li>
              );
            })}
            <li>
              <a href={`#press-articles`}>Press Articles</a>
            </li>
            {/* <li>
              <a href={`#eligibility`}>Eligibility</a>
            </li> */}
          </ul>
        </div>
        <h3 className="mt-5">
          Best Performance by a Featured Actor in a Musical
          <br />
          <span className="font-light">
          ROGER BART
          </span>
        </h3>

        <h3 className="mt-5 mb-3">
          Best Scenic Design of a Musical
          <br />
          <span className="font-light">
          TIM HATLEY & FINN ROSS
          </span>
        </h3>
      

        <div className="sideBySide"  style={{marginBottom: '5rem'}}>
          <div>
            <h3 className="mb-3">
              ANATOMY OF A <br className="hidden-tablet" /> SCENE
            </h3>
            <div className="full-video">
              <ReactPlayer
                url={`https://www.youtube.com/watch?v=${fullWidthVideo.id}`}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
          <div>
            <h3 className="mb-3">DREAMERS</h3>
            <div className="full-video">
              <ReactPlayer
                url={`https://www.youtube.com/watch?v=${anotherVideo.id}`}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
        </div>
        <div className="full-video">
          {/* <div>
            <span>
              <PlayIcon />
            </span>
          </div> */}
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${secondFullWidthVideo.id}`}
            width={"100%"}
            height={"100%"}
          />
        </div>

        <div className="cards-wrapper">
          {cards.map((card, i) => {
            return (
              <CardSection
                data={card}
                key={i}
                openModalandSetVideo={openModalandSetVideo}
              />
            );
          })}
        </div>
        <div className="press">
          <span className="anchor" id="press-articles"></span>
          <h3>Press Articles</h3>
          <ArticleSlider data={articles} />
        </div>
        {/* <Consideration data={consideration} /> */}
      </ContentHubStyles>
      <StyledModal ref={target}>
        <div className="inner-modal">
          <span onClick={() => setOpen(false)}>
            <CloseIcon />
          </span>
          {video !== true ? (
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${video.id}`}
              width={"100%"}
              height={"100%"}
              ref={videoRef}
            />
          ) : (
            <iframe
              width={"100%"}
              height={"100%"}
              src="https://www.youtube.com/embed/wsGTQPzf_W4?si=Bc4-HziJw1hrs9aT&amp;start=78"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          )}
        </div>
      </StyledModal>
    </Layout>
  );
};

export default ContentHub;

const CardSection = ({ data, openModalandSetVideo }) => {
  const { name, cards } = data;
  return (
    <CardSectionStyles>
      <span id={slugify(name)} className="anchor"></span>
      <h3>{name}</h3>
      <div className="wrapper">
        {cards.map((card, i) => {
          return (
            <SingleCard
              data={card}
              key={i}
              openModalandSetVideo={openModalandSetVideo}
            />
          );
        })}
      </div>
    </CardSectionStyles>
  );
};

const SingleCard = ({ data, openModalandSetVideo }) => {
  const card = data;
  const videoLink = getVideoId(card.videoLink);
  return (
    <div
      className="card"
      onClick={() => openModalandSetVideo(videoLink, card.video, card.defaultPlayer)}
    >
      <div className="image">
        <GatsbyImage image={getImage(card.image)} alt="" />
        {card.video && (
          <span>
            <PlayIcon />
          </span>
        )}
      </div>
      <p>{card.text}</p>
      {card.audio && (
        <div className="audio">
          <a href={card.videoLink} target="_blank" rel="noopener noreferrer">
            <p>LISTEN HERE</p>
          </a>
        </div>
      )}
      {card.external && (
        <div className="audio">
          <a href={card.videoLink} target="_blank" rel="noopener noreferrer">
            <p>WATCH HERE</p>
          </a>
        </div>
      )}
    </div>
  );
};

const StyledArticle = styled.article`
  width: 90%;
  margin: 0 auto;
  background: #fff;
  max-width: 300px;
  height: 400px;
  text-align: left;
  @media ${media.lg} {
    max-width: 420px;
  }
  @media ${media.lg} {
    max-width: 420px;
  }
  .text {
    padding: 1rem;
    p {
      color: #000;
      margin: 0;
      padding: 0.5rem 0;
      font-size: 16px;
    }
    a {
      text-decoration: underline 2px;
      color: #000;
      font-weight: 900;
      letter-spacing: 1px;
      font-family: ${(props) => props.theme.font.family.black};
    }
  }
`;

const ArticleSlider = ({ data }) => {
  const sliderRef = useRef();
  const next = () => {
    sliderRef.current.slickNext();
  };
  const prev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="slider">
      <Slider {...settings} ref={sliderRef}>
        {data.map((article, i) => {
          return (
            <StyledArticle key={i}>
              <div>
                <GatsbyImage image={getImage(article.img)} alt="" />
              </div>
              <div className="text">
                <div className="logo">
                  <GatsbyImage image={getImage(article.logo)} alt="" />
                </div>
                <p>{article.text}</p>
                <a
                  href={article.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  READ ARTICLE
                </a>
              </div>
            </StyledArticle>
          );
        })}
      </Slider>
      <div className="button">
        <div onClick={() => prev()}>
          <CarouselArrow />
        </div>
        <div onClick={() => next()}>
          <CarouselArrow />
        </div>
      </div>
    </div>
  );
};

const StyledConsideration = styled.div`
  width: 100%;
  position: relative;
  background: #000522;
  padding: 2rem 0;
  div {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    @media ${media.md} {
      width: 100%;
    }
    .names {
      margin-top: 2rem;
      div {
        .title {
          display: inline-block;
          font-family: ${(props) => props.theme.font.family.black};
          font-weight: 800;
          background: rgb(255, 49, 0);
          background: linear-gradient(
            223deg,
            rgba(255, 49, 0, 1) 0%,
            #ff7e00 50%,
            rgba(255, 204, 0, 1) 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin: 0;
          font-size: 20px;
          padding: 0;
          @media ${media.md} {
            background: linear-gradient(
              280deg,
              rgba(255, 49, 0, 1) 0%,
              #ff7e00 20%,
              rgba(255, 204, 0, 1) 100%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          @media ${media.xl} {
            font-size: 30px;
          }
        }
        @media ${media.md} {
          display: grid;
          margin-bottom: 5px;
          grid-template-columns: 1fr 1fr;
          grid-gap: 5px;
          .title {
            text-align: right;
          }
          .role {
            padding: 0;
            margin: 0;
            text-align: left;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
`;

const Consideration = ({ data }) => {
  return (
    <StyledConsideration>
      <span className="anchor" id="eligibility"></span>
      <div>
        <h3>
          For your <br className="hidden-tablet" /> consideration
        </h3>
        <div className="names">
          {data.map((name, i) => {
            return (
              <div key={i}>
                <p className="title">{name.name}</p>
                <p className="role">{name.role}</p>
              </div>
            );
          })}
        </div>
      </div>
    </StyledConsideration>
  );
};

const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="49"
    viewBox="0 0 54 49"
    fill="none"
  >
    <rect y="3.32312" width="3.32308" height="41.5385" fill="white" />
    <rect
      x="5.81543"
      y="3.32312"
      width="3.32308"
      height="41.5385"
      fill="white"
    />
    <rect
      x="11.6307"
      y="3.32312"
      width="3.32308"
      height="41.5385"
      fill="white"
    />
    <path
      d="M21.8615 10.156L46 24.0923L21.8615 38.0287L21.8615 10.156Z"
      fill="white"
      stroke="white"
      stroke-width="8"
    />
    <path
      d="M47.3539 24.0923L21.1846 39.2011L21.1846 8.98346L47.3539 24.0923Z"
      fill="url(#paint0_linear_256_1144)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_256_1144"
        x1="47.3539"
        y1="24.0923"
        x2="21.4854"
        y2="9.35327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0000" />
        <stop offset="1" stop-color="#FFCC00" />
      </linearGradient>
    </defs>
  </svg>
);

const CarouselArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="36"
    viewBox="0 0 40 36"
    fill="none"
  >
    <rect y="2.46155" width="2.46154" height="30.7692" fill="white" />
    <rect
      x="4.30774"
      y="2.46155"
      width="2.46154"
      height="30.7692"
      fill="white"
    />
    <rect
      x="8.61536"
      y="2.46155"
      width="2.46154"
      height="30.7692"
      fill="white"
    />
    <mask id="path-4-inside-1_280_1242" fill="white">
      <path d="M40 17.8462L13.2308 33.3014L13.2308 30.4616L35.2308 17.8462L13.2308 5.38467L13.2308 2.39099L40 17.8462Z" />
    </mask>
    <path
      d="M40 17.8462L13.2308 33.3014L13.2308 30.4616L35.2308 17.8462L13.2308 5.38467L13.2308 2.39099L40 17.8462Z"
      fill="white"
    />
    <path
      d="M40 17.8462L44 24.7744L56 17.8462L44 10.918L40 17.8462ZM13.2308 33.3014L5.23077 33.3014L5.23077 47.1578L17.2308 40.2296L13.2308 33.3014ZM13.2308 2.39099L17.2308 -4.53721L5.23077 -11.4654L5.23077 2.39099L13.2308 2.39099ZM13.2308 5.38467L5.23077 5.38467L5.23077 10.0475L9.2879 12.3455L13.2308 5.38467ZM13.2308 30.4616L9.25121 23.5216L5.23077 25.8271L5.23077 30.4616L13.2308 30.4616ZM35.2308 17.8462L39.2103 24.7862L51.3873 17.8036L39.1736 10.8853L35.2308 17.8462ZM36 10.918L9.23077 26.3732L17.2308 40.2296L44 24.7744L36 10.918ZM9.23077 9.31919L36 24.7744L44 10.918L17.2308 -4.53721L9.23077 9.31919ZM21.2308 5.38468L21.2308 2.39099L5.23077 2.39099L5.23077 5.38467L21.2308 5.38468ZM21.2308 33.3014L21.2308 30.4616L5.23077 30.4616L5.23077 33.3014L21.2308 33.3014ZM17.2103 37.4016L39.2103 24.7862L31.2512 10.9062L9.25121 23.5216L17.2103 37.4016ZM39.1736 10.8853L17.1736 -1.5762L9.2879 12.3455L31.2879 24.8071L39.1736 10.8853Z"
      fill="url(#paint0_linear_280_1242)"
      mask="url(#path-4-inside-1_280_1242)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_280_1242"
        x1="40"
        y1="25.1355"
        x2="21.9395"
        y2="4.63138"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0000" />
        <stop offset="1" stop-color="#FFCC00" />
      </linearGradient>
    </defs>
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 455.111 455.111"
    viewBox="0 0 455.111 455.111"
  >
    <circle cx="227.556" cy="227.556" r="227.556" fill="#e24c4b"></circle>
    <path
      fill="#d1403f"
      d="M455.111 227.556c0 125.156-102.4 227.556-227.556 227.556-72.533 0-136.533-32.711-177.778-85.333 38.4 31.289 88.178 49.778 142.222 49.778 125.156 0 227.556-102.4 227.556-227.556 0-54.044-18.489-103.822-49.778-142.222 52.623 41.243 85.334 105.243 85.334 177.777z"
    ></path>
    <path
      fill="#fff"
      d="M331.378 331.378c-8.533 8.533-22.756 8.533-31.289 0l-72.533-72.533-72.533 72.533c-8.533 8.533-22.756 8.533-31.289 0-8.533-8.533-8.533-22.756 0-31.289l72.533-72.533-72.533-72.533c-8.533-8.533-8.533-22.756 0-31.289 8.533-8.533 22.756-8.533 31.289 0l72.533 72.533 72.533-72.533c8.533-8.533 22.756-8.533 31.289 0 8.533 8.533 8.533 22.756 0 31.289l-72.533 72.533 72.533 72.533c8.533 8.533 8.533 22.755 0 31.289z"
    ></path>
  </svg>
);
